<script>
export default {
  name: 'ModalPositionDetails',
  components: {
    PositionDetails: () => import('@/components/positions/PositionDetails')
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    placeholder: {
      type: Object,
      default: () => ({})
    },
    onFavorite: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      showModal: false,
      isClosed: false
    }
  },
  methods: {
    close () {
      this.isClosed = true
      this.handleOverflow()
      this.$emit('close')
      this.$emit('open')
    },
    handleKey (event) {
      if (event.key === 'Escape') {
        this.close()
      }
    },
    handleOverflow () {
      const overflow = document.querySelector('html')
      if (!overflow.style.overflowY || overflow.style.overflowY === 'scroll') overflow.style.overflowY = 'hidden'
      else overflow.style.overflowY = ''
    }
  },
  created () {
    this.handleOverflow()
  },
  beforeDestroy () {
    if (!this.isClosed) this.handleOverflow()
  }
}
</script>
<template>
  <v-dialog :value="true" transition="dialog-bottom-transition" persistent no-click-animation fullscreen content-class="v-dialog-position-detail" @keydown="handleKey">
    <v-toolbar dark style="height: 75px">
      <v-btn class="mb-3" @click="close" :ripple="false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card class="content-details--wrapper">
      <position-details :id="id" :placeholder="placeholder" :onFavorite="onFavorite" />
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.v-dialog-position-detail {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  box-shadow: none;
  @keyframes reveal {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.8;
    }
  }
  .v-toolbar{
    opacity: 0;
    animation: reveal 0.1s normal forwards;
    animation-delay: 0.3s;
    width: 100%;
    .v-toolbar__content {
      width: 100vw;
      margin-left: -20px;
      .v-btn {
        height: 100px;
        width: 101vw;
        justify-content: flex-end;
      }
    }
    .v-icon{
      color: #FFFFFF;
      opacity: none;
    }
  }
  .content-details--wrapper{
    padding-top: 40px !important;
    background-color: #f8f8f8;
    border-radius: 30px 30px 0 0;
    position: absolute;
    top: 45px;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: scroll;
  }
}
</style>
