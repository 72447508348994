<script>

export default {
  components: { },
  name: 'EmptyList',
  props: {
    title: {
      type: String
    },
    textOne: {
      type: String
    },
    textTwo: {
      type: String
    },
    buttonText: {
      type: String
    },
    image: {
      type: String
    },
    validateRole: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<template>
  <v-card class="content-empty-list--container max-auto" flat>
    <div class="content-empty-list--header">
        <img :src="image" :alt="title"/>
        <h5 class="content-empty-list--title">{{ title }}</h5>
        <p class="content-empty-list--text-one" v-html="textOne"></p>
    </div>
    <div class="content-empty-list--body" v-if="buttonText">
      <v-card-actions class="mt-3">
        <v-btn class="btn transform-unset" dark :color="getPrimaryColor" @click="$emit('btnClick')" v-if="validateRole">
          <v-icon class="mr-1">mdi-magnify</v-icon>
          {{ buttonText }}
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>
<style lang="scss">
  .content-empty-list--container {
    margin-bottom: 60px;
    width: 100%;
    height: 380px;
    display: flex;
    justify-content: center;
    align-items: center ;
    flex-direction: column;
    .content-empty-list--header {
      text-align: center;
      .content-empty-list--title {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: #212121;
        margin-top: 24px;
      }
      .content-empty-list--text-one {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #A0A0A0;
        margin-bottom: 0;
      }
    }
    .v-card__actions {
      display: flex;
      justify-content: center;
      align-items: center ;
    }
    .btn.transform-unset {
      padding: 24px 16px;
    }
  }
</style>
