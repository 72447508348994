<script>
import SliderContent from '@/components/general/SliderContent'
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'GenericSlider',
  components: { SliderContent },
  props: {
    title: {
      type: String
    },
    description: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      required
    },
    type: {
      type: String,
      required
    },
    isLoading: {
      type: Boolean
    },
    emptyText: {
      type: String
    }
  },
  methods: {
    emitFunction (data) {
      this.$emit('emitGenericSlider', data)
    }
  },
  computed: {
    wrapperStyle () {
      return {
        top: this.description ? '5px' : '0px'
      }
    },
    titleStyle () {
      return {
        lineHeight: this.description ? '25px' : '52px'
      }
    }
  }
}
</script>
<template>
  <div class="generic-slider--container">
    <div class="generic-slider--header_wrapper" :style="wrapperStyle">
      <h5 :style="titleStyle">{{ $t(title) }}</h5>
      <p v-if="description">{{ description ? $t(description) : description }}</p>
    </div>
    <slider-content
      v-if="items.length"
      :isLoading=isLoading
      :selectedTab="0"
      :tabItems="[items]"
      :type="type"
      @emitSliderContent="emitFunction"
    />
  </div>
</template>
<style lang="scss">
.generic-slider--container {
  position: relative;

  .generic-slider--header_wrapper {
    text-align: left;
    position: absolute;
    h5 {
      font-size: 16px;
    }
    p.title-description-slot--description {
    font-family: 'Lato';
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #7E7D77;
  }
  }

  .slider-content--container {
    margin: 0 -8px;

    .slider-button--prev {
      right: 56px;
    }

    .slider-button--next {
      right: 8px;
    }
  }

  .empty-container {
    margin: 0 auto;
    max-width: 400px;
    .empty-text {
      color: $neutral-medium;
      margin-bottom: 8px;
    }
    .v-image {
      margin: 20px auto 16px;
    }
  }
  .v-slide-group__content {
    grid-gap: 0;
  }
}
</style>
