<script>
import ViewAllTemplate from './ViewAllTemplate.vue'
import CandidatureListItem from '@/components/general/CandidatureListItem.vue'
export default {
  name: 'NotCompletedCandidatures',
  components: { ViewAllTemplate, CandidatureListItem },
  props: {
    positions: {
      type: Object
    }
  },
  methods: {
    removePosition (item) {
      this.positions.notFinished = this.positions.notFinished.filter(candidature => candidature.position.status !== 'no_filter' && candidature.position.id !== item)
    },
    deleteCandidature (payload) {
      this.$store.dispatch('attemptDeleteCandidature', payload).then(() => {
        const aggregator = { aggregator: this.getCompanyDomainAndWorkspace() }
        this.$store.dispatch('attemptGetUserMatches', aggregator)
        this.$store.dispatch('attemptGetUserClosedMatches', aggregator)
        this.$store.dispatch('attemptGetUserFavoritePosition', aggregator)
      })
    }
  }
}
</script>
<template>
  <div class="not-completed--container" v-if="positions.notFinished.length">
    <view-all-template title="candidature.list:not.finished.title" description="candidature.list:not.finished.subtitle"
      :items="positions.notFinished">
      <template v-slot:item="slotProps">
        <candidature-list-item :candidature="slotProps.item" @refreshGiveUp="removePosition(slotProps.item.position.id)"
          @confirmReject="deleteCandidature">
        </candidature-list-item>
      </template>
    </view-all-template>
  </div>
</template>
<style>
.not-completed--container {
  margin-top: 80px;
  margin-bottom: -40px;
  font-size: 16px;

}
</style>
