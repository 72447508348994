<script>
import ValidationTemplate from '@/components/general/ValidationTemplate'
export default {
  name: 'Textarea',
  components: { ValidationTemplate },
  props: {
    validation: {
      type: Object
    },
    manualValidation: {
      type: Boolean,
      default: false
    },
    hideValidation: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    updateValidation () {
      if (!this.manualValidation && this.validation) this.validation.$touch()
    }
  }
}
</script>
<template>
  <div class="input-field--wrapper">
    <validation-template
      :validation="validation"
      :manualValidation="manualValidation"
      :hide-validation="hideValidation"
    >
      <v-textarea
        v-bind="$attrs"
        v-on="$listeners"
        attach
        @blur="updateValidation"
        hide-details="auto"
      />
    </validation-template>
  </div>
</template>
