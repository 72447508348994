<script>
export default {
  name: 'IconCard',
  props: {
    id: {
      type: String
    },
    icon: {
      type: String
    },
    title: {
      type: String
    }
  },
  methods: {
    selectCategory () {
      this.$emit('emitIconCard', this.id)
    }
  }
}
</script>
<template>
    <div class="icon-card--container" @click="selectCategory">
        <v-icon :color="getPrimaryColor" size="24">{{ icon }}</v-icon>
        <h4>{{ $t(title) }}</h4>
    </div>
</template>
<style lang="scss">
div.icon-card--container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 24px 24px 12px 24px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  margin: 12px 8px;
  h4 {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #454545;
  }
  p {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #7E7D77;
  }
  &:hover {
    opacity:0.8;
    cursor:pointer;
  }
}
</style>
