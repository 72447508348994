<script>
import { required } from 'vuelidate/lib/validators'
import learningCardMixin from '@/mixins/learningCard'
import LearningCardImageInfo from '@/components/general/LearningCardImageInfo'
import ConfirmationModal from './ConfirmationModal.vue'
import ShareModal from '@/components/general/ShareModal'
import ImageCardType from '@/components/general/ImageCardType'
import ModalListDetails from '../lists/ModalListDetails.vue'
export default {
  name: 'LearningCardList',
  mixins: [learningCardMixin],
  props: {
    list: {
      type: Object,
      required
    },
    originWindow: {
      type: String,
      default: ''
    },
    showItemsProgress: {
      type: Boolean,
      default: false
    },
    allowUpdate: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      menuOptions: ['edit', 'delete'],
      addActionOptions: [],
      showRemovalModal: false,
      shareModal: false,
      showModal: false,
      listIdProp: {}
    }
  },
  components: {
    ImageCardType,
    ShareModal,
    LearningCardImageInfo,
    ConfirmationModal,
    ModalListDetails
  },
  methods: {
    showShareModal () {
      this.shareModal = true
    },
    closeShareModal () {
      this.shareModal = false
    },
    seeMoreAction (item) {
      const params = { listId: item.id }

      if (this.$route.name === 'individual.discovery.index.search') {
        this.showModal = true
        this.listIdProp = params
      } else {
        this.$router.push({ name: `${this.$route.name}.lists.details`, params })
        // this.$emit('showModalListDetails', params)
      }
    },
    addAction (item) {
      this.$router.push({ name: `${item.type}.list.association`, params: { [`${item.type}Id`]: item.id } })
    },
    executeAction (item, action) {
      const params = { listId: item.id }
      switch (action) {
        case 'edit':
          this.$router.push({ name: `${this.$route.name}.lists.manage`, params })
          break

        case 'delete':
          this.showRemovalModal = item
          break
      }
    },
    removeItem (item) {
      this.$store.dispatch('attemptRemoveList', item.id)
        .then(({ data }) => {
          if (data) {
            this.$store.dispatch('attemptGetWorkspaceLists', { filter: this.$store.getters.getCurrentListFilters, workspaceId: this.list.workspaceId })
          }
        })
        .catch(() => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'error',
            title: this.$t('content:toast.delete.error')
          })
        })
        .finally(() => {
          this.showRemovalModal = null
        })
    },
    favoriteAction (item) {
      this.list.isSaved = !this.list.isSaved
      this.$store.dispatch('attemptToggleFavoriteList', item.id)
        .then(() => {
          !item.isSaved && this.$store.commit('toggleUserFavoriteList', item.id)
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'success',
            title: this.$t(`user.favorite.list.toggle:${item.isSaved ? 'favorited' : 'unfavorited'}`),
            description: this.$t(`user.favorite.list.toggle:${item.isSaved ? 'favorited' : 'unfavorited'}.description`)
          })
        })
    },
    applyToggleListConclusion (item) {
      this.$store.dispatch('attemptSetListInteraction', { listId: item.id, interaction: 'conclusion' })
        .then(({ data }) => {
          this.list.interactionType = data.currentStatus
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'success',
            title: this.$t(`${item.type}.conclusion.toggle:success.${this.list.interactionType === 'conclusion' ? 'done' : 'undo'}`)
          })
        })
        .finally(() => {
          this.$store.dispatch('attemptGetMyselfLists')
          this.showConfirmToggleConclusion = false
        })
    },
    startProgress () {
      this.$store.dispatch('attemptSetListInteraction', { listId: this.list.id, interaction: 'in_progress' })
        .then(({ data }) => {
          this.list.interactionType = data.currentStatus
        })
        .finally(() => {
        })
    },
    shareDetails () {
      return {
        link: this.getBaseUrl() + `/my/discovery/list/details/${this.list.id}`
      }
    }
  }
}
</script>
<template>
  <div class="learning-card-list--wrapper discovery-list--container">
    <learning-card-image-info
      :item="parseLearningPayload(list, 'list')"
      :menuAction="executeAction"
      :seeMoreAction="seeMoreAction"
      :addAction="addAction"
      getTitleIcon="mdi-format-list-bulleted"
      :getTitle="$t('global:list')"
      :allowUpdate="allowUpdate"
      :menuOptions="menuOptions"
      :addActionOptions="addActionOptions"
      titlePosition="top"
      defaultCoverImage="/assets/images/list-cover-default.png"
      :coverImageHeight="180"
      :showItemsProgress="showItemsProgress"
      :checkAction="applyToggleListConclusion"
      :favoriteAction="favoriteAction"
      :shareAction="showShareModal"
      @interaction="$emit('interaction')"
      @toggleProgress="startProgress"
    ></learning-card-image-info>
    <share-modal
      v-model="shareModal"
      :details="shareDetails()"
      @close="closeShareModal"
    >
      <image-card-type
        :card="parseLearningPayload(list, 'list')"
        defaultImage="/assets/images/list-cover-default.png"
      />
    </share-modal>
    <confirmation-modal
      :show="!!showRemovalModal"
      :title="$t('content.remove:title')"
      :description="$t('content.remove:info')"
      :confirmText="$t('global:delete')"
      :btnConfirmText="false"
      @confirm="removeItem(showRemovalModal)"
      :cancelText="$t('global:cancel')"
      @cancel="showRemovalModal = null"
    />
    <ModalListDetails v-if="showModal" :listIdProp="listIdProp" @close="showModal = false"></ModalListDetails>
  </div>
</template>
<style lang="scss">
.learning-card-list--wrapper {
  .learning-card-image-container {
    margin: 0;
    width: 100%;
  }
}
</style>
