<script>
import ModalContentCourseDetails from './ModalContentCourseDetails.vue'

export default {
  name: 'NewContentCard',
  components: {
    ModalContentCourseDetails
  },
  props: {
    course: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      loading: false,
      showModal: false,
      item: { ...this.course }
    }
  },
  methods: {
    toggleFavorite () {
      const isFavorite = this.item.isFavorite
      this.item.isFavorite = !isFavorite
      this.$store
        .dispatch(isFavorite ? 'attemptRemoveFavoriteCourse' : 'attemptSaveFavoriteCourse', { course_id: this.course.id })
        .then(() => {
          this.item.isFavorite = !isFavorite
        })
        .catch((err) => {
          if (err.code === 403 && err.error === 'not_allowed') {
            this.showNotAllowedModal = true
          } else {
            this.directToRoute()
            this.$store.dispatch('attemptSetFeedbackMsg', {
              type: 'error',
              title: this.$t('content:error.load')
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleFavorite (isFavorite) {
      this.item.isFavorite = isFavorite
    },
    handleShareCourse (url) {
      if (url) {
        this.$store.dispatch('attemptSetFeedbackMsg', {
          type: 'success',
          title: this.$t('content:success.share')
        })
        navigator.clipboard.writeText(url)
      } else {
        this.$store.dispatch('attemptSetFeedbackMsg', {
          type: 'error',
          title: this.$t('content:error.share')
        })
      }
    }
  },
  computed: {
    getshowModal () {
      return this.$store.getters.getshowModal
    }
  },
  created () {
    this.isFavoritePosition = this.isFavorite
  }
}
</script>
<template>
    <v-card class="content-card--container">
      <div v-if="loading"></div>
      <ModalContentCourseDetails
        v-if="showModal"
        @close="showModal = !showModal"
        :id="item.id"
        :placeholder="item"
        :onFavorite="handleFavorite"
      />
      <div class="content-card--upper" :style="{backgroundColor: getAccentColor }">
        <div class="content-card--upper-actions">
          <v-icon class="cursor-pointer mr-2" color="#fff" @click="handleShareCourse(fixedCourseUrl(course.url))">mdi-share-variant</v-icon>
          <v-icon class="cursor-pointer" color="#fff" @click="toggleFavorite()">{{item.isFavorite ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon>
        </div>
        <div class="content-card--upper-title">
          <h3 class="title">{{ item.title }}</h3>
        </div>
        <div class="content-card--upper-owner">
          <v-avatar :color="'#FFF'" size="40px" class="mr-2">
            <v-img contain height="40px" width="40px" :src="item.provider.logo" :alt="item.provider.log"></v-img>
          </v-avatar>
          <p class="content-card--owner-name text-truncate">{{ item.provider.name }}</p>
        </div>
      </div>
      <div class="content-card--lower">
        <div class="content-card--lower-data">
          <div class="content-card--content-type mb-3">
            <v-icon :color="getAccentColor">mdi-school</v-icon>
            <p class="course-type" :style="{color: getAccentColor}">{{ $t('content.type:course') }}</p>
          </div>
          <div class="content-card--card-data">
            <v-icon small :color="getAccentColor">mdi-currency-usd</v-icon>
            <p class="data-text">{{ parseTextFormat({key: 'pricing', text: item.price, currency: item.priceCurrency}) }}</p>
          </div>
          <div class="content-card--card-data">
            <v-icon small :color="getAccentColor">mdi-web</v-icon>
            <p class="data-text">{{ parseTextFormat({key: 'modality', text: item.modality}) }}</p>
          </div>
        </div>
        <div class="content-card--footer_wrapper mt-4">
          <v-btn class="btn bold transform-unset" outlined :color="getAccentColor" @click.prevent="showModal = !showModal" :href="`${getWorkspaceRoot()}/discovery/course/${item.id}`">
            {{ $t('global:show.more') }}
          </v-btn>
        </div>
      </div>
    </v-card>
</template>
<style lang="scss">
.content-card--container {
  margin: 8px;
  width: 296px;
  height: 380px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) !important;
  .content-card--upper {
    padding: 16px;
    height: 220px;
    .content-card--upper-actions {
      text-align: right;
    }
    .content-card--upper-title {
      height: calc(24px * 4);
      overflow: hidden;
      margin: 16px 0;
      h3.title {
        font-family: $lato;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #FFFFFF;
        text-align: left;
        text-transform: capitalize;
      }
    }
    .content-card--upper-owner {
      display: flex;
      align-items: center;
      .v-avatar {
        border: 1px solid #FFFFFF;
      }
      p.content-card--owner-name {
        margin: 0;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 900;
        font-size: 16px;
        line-height: 28px;
        color: #FFFFFF;
      }
    }
  }
  .content-card--lower {
    padding: 16px;
    padding-top: 16px;
    padding-bottom: 0;
    text-align: right;
    .content-card--lower-data {
      margin-bottom: 8px;
      .content-card--content-type {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        p.course-type {
          margin-bottom: 0;
          margin-left: 8px;
          font-family: $lato;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          color: #62B55A;
        }
      }
      .content-card--card-data {
        display: flex;
        align-items: center;
        margin-bottom: 4px;
        p.data-text {
          margin-bottom: 0;
          margin-left: 8px;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #454545;
        }
      }
    }
    .content-card--footer_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .v-chip {
        background: linear-gradient(0deg, rgba(98, 181, 90, 0.1), rgba(98, 181, 90, 0.1)), #FFFFFF;;
        height: 25px;
        p.data-text-chip{
          font-family: 'Lato';
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
