<script>
// import InputField from '@/components/general/InputField.vue'
import Breadcrumbs from '@/components/general/Breadcrumbs'
import EmptyList from '@/components/general/EmptyList.vue'
import Loading from '@/components/general/Loading.vue'
export default {
  name: 'ViewAllTemplate',
  props: {
    title: {
      type: String
    },
    description: {
      type: String
    },
    items: {
      type: Array
    }
  },
  components: {
    // InputField,
    Breadcrumbs,
    EmptyList,
    Loading
  },
  data () {
    return {
      isLoading: true
    }
  },
  computed: {
    breadcrumbsPaths () {
      const isBusiness = this.$route.path.includes('/c/')
      const path = [
        {
          text: isBusiness ? 'internal.mobility:title' : 'main.header:candidacy',
          href: isBusiness ? this.$router.resolve({ name: 'business.internal.positions', params: { companyDomain: this.selectedWorkspace.subdomain } }).href : this.$router.resolve({ name: this.selectedWorkspace.type + '.candidature.index' }).href
        },
        {
          text: this.title,
          href: this.$route.path
        }
      ]
      return path
    }
  },
  created () {
    setTimeout(() => {
      this.isLoading = false
    }, 500)
  }
}
</script>
<template>
  <div class="view-all-template--container">
    <div class="view-all-template--content">
      <breadcrumbs :items="breadcrumbsPaths" v-if="false" />
      <h2>{{ $t(title) }}</h2>
      <p>{{ $t(description) }}</p>
    </div>
    <div class="view-all--body">
      <Loading class="loading-candidature--wrapper" v-if="isLoading"/>
      <div class="view-all--items" v-else>
        <div v-if="items.length">
        <template v-for="item in items">
          <slot name="item" :item="item"></slot>
        </template>
      </div>
        <empty-list
        v-else
        :title="$t('empty.state.title:empty.content')"
        :image="'/assets/images/empty-box.png'"></empty-list>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.view-all-template--container {

  .view-all-template--content {
    margin-top: -50px;

    h2{
      font-size: 16px;
    }

    p {
      color: #A0A0A0;
    }
  }

  .view-all--body {
    .view-all--filter {
      .input-field--wrapper {
        width: 250px;
        max-width: 100%;

        .v-input {
          background-color: #fff;
          box-shadow: 0px 1px 1px rgba(66, 66, 66, 0.08), 0px 1px 3px 1px rgba(66, 66, 66, 0.16);
        }
      }
    }
  }
}
</style>
